import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div style={{textAlign:'center', padding:'2em'}}>
      <h1>404 NOT FOUND</h1>
      <h2>We are sorry, Page not found!</h2>
      <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
      <a href="/">Back To Homepage</a>
    </div>
  </Layout>
)

export default NotFoundPage
